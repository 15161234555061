<template>
  <div id="reset-password">
    <v-toolbar flat color="dark">
      <v-btn @click="checkBackFunction()" icon>
        <v-icon color="primary" size="30"> mdi-arrow-left </v-icon>
      </v-btn>
      <v-toolbar-title class="steps" v-if="gs.isMobile()">
        {{ $t("alpha_register_step_count", { step: step, totalSteps: 3 }) }}
      </v-toolbar-title>
      <img
        alt="DXA"
        v-if="!gs.isMobile()"
        src="../../../../assets/dealboard-logo-white.svg"
        height="40px"
        class="pl-10"
      />
      <v-spacer />
      <v-menu
        v-if="
          !gs.isMobile() &&
          !$route.name.includes('AlphaRegisterClient') &&
          $route.name != 'AlphaUserRegisterClient' &&
          $route.name != 'WelcomePage'
        "
        style="z-index: 1000"
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-card
            class="btn_lang d-flex mr-5"
            v-if="selectedLanguage != null"
            style="width: 125px"
            height="30px"
            min-width="100px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              max-width="23px"
              height="23px"
              :src="selectedLanguageAvatar()"
            ></v-img>
            <p class="p_br" v-if="selectedLanguage.value == 'pt'">
              PORTUGUÊS/BR
            </p>
            <p class="p_en" v-else>ENGLISH</p>
          </v-card>
          <v-btn
            v-else
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-voice</v-icon>
          </v-btn>
        </template>

        <v-list :tile="false" flat nav>
          <v-list-item
            v-for="(l, i) in langs"
            :key="i"
            @click="changeLanguage(l)"
          >
            <v-list-item-title v-text="l.text" style="line-height: 1.4" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      v-if="gs.isMobile()"
      color="primary"
      height="4"
      class="dxa-progress-bar"
      style="margin-bottom: -10px"
      :value="(step * 100) / 3"
    ></v-progress-linear>
    <v-container
      :class="gs.isMobile() ? 'pa-4' : ''"
      :style="gs.isMobile() ? '' : 'width: fit-content'"
    >
      <v-slide-y-transition appear>
        <div class="justify-center">
          <h2
            v-if="step == 1"
            class="confirm_title mt-2"
            :style="gs.isMobile() ? '' : 'text-align: left'"
          >
            {{ $t("confirm_email") }}
          </h2>
          <h2
            v-else
            class="confirm_title mt-2"
            :style="gs.isMobile() ? '' : 'text-align: left'"
          >
            {{ $t("pwd_reset") }}
          </h2>
          <h3
            v-if="step == 1"
            class="confirm_content mt-6"
            :style="gs.isMobile() ? '' : 'text-align: left; color: #9C9C9C'"
          >
            {{ $t("enter_email") }}
          </h3>
          <h3
            v-else-if="!gs.isMobile()"
            class="confirm_content mt-6"
            style="text-align: left; color: #9c9c9c"
          >
            {{ $t("pwd_reset_email") }}
          </h3>
          <h3 v-else class="confirm_content mt-6">
            {{ $t("new_password_link", { account: account }) }}
            <strong>{{ account }}</strong
            >.
            {{ $t("email_access") }}
          </h3>
          <div v-if="email_sent && step == 2" class="mt-12">
            <div v-if="!gs.isMobile()">
              <h3 class="steps mb-3" style="color: var(--dark)">
                {{
                  $t("alpha_register_step_count", { step: step, totalSteps: 3 })
                }}
              </h3>
              <v-progress-linear
                color="primary"
                height="4"
                class="dxa-progress-bar"
                style="margin-bottom: -10px"
                :value="(step * 100) / 3"
              ></v-progress-linear>
              <v-card class="desktop_card">
                <!-- ao matar essa foto, quebramos o layout! por isso retiramos ela da tela com um opacity: 0 -->
                <img
                  src="../../../../assets/dxa-x.png"
                  class="x_logo"
                  alt="DXA"
                  style="opacity: 0"
                />
                <img
                  src="../../../../assets/dxa-stars-background.png"
                  class="stars"
                  alt="DXA"
                />
                <div class="card_content">
                  <h3 class="confirm_content" style="text-align: left">
                    {{ $t("new_password_link", { account: account }) }}
                    <strong>{{ account }}</strong
                    >.
                    <br />
                    {{ $t("email_access") }}
                  </h3>
                  <v-btn
                    class="resend_btn mt-6"
                    text
                    :color="color"
                    style="padding-left: 0 !important"
                    @click="reset_password()"
                    >{{ $t("resend_link") }}</v-btn
                  >
                </div>
              </v-card>
            </div>
            <div style="text-align: center" v-else>
              <v-btn
                class="resend_btn mt-6"
                text
                :color="color"
                style="padding: 0"
                @click="reset_password()"
                >{{ $t("resend_link") }}</v-btn
              >
            </div>
          </div>
          <div v-else class="mt-12">
            <div v-if="!gs.isMobile()">
              <h3 class="steps mb-3" style="color: var(--dark)">
                {{
                  $t("alpha_register_step_count", { step: step, totalSteps: 3 })
                }}
              </h3>
              <v-progress-linear
                color="primary"
                height="4"
                class="dxa-progress-bar"
                style="margin-bottom: -10px"
                :value="(step * 100) / 3"
              ></v-progress-linear>
              <v-card class="desktop_card">
                <!-- ao matar essa foto, quebramos o layout! por isso retiramos ela da tela com um display none -->
                <img
                  src="../../../../assets/dxa-x.png"
                  class="x_logo"
                  alt="DXA"
                  style="opacity: 0"
                />
                <img
                  src="../../../../assets/dxa-stars-background.png"
                  alt="Icon"
                  class="stars"
                />
                <div class="card_content">
                  <h3 class="confirm_content" style="text-align: left">
                    {{ $t("enter_email") }}
                  </h3>
                  <v-form
                    @submit.stop.prevent="checkFormEmail()"
                    ref="form_email"
                    v-model="valid_email"
                    :lazy-validation="lazy_email"
                    style="width: 50%"
                  >
                    <div class="div_email mt-11">
                      <h3 class="email_title">E-mail</h3>
                      <v-text-field
                        solo
                        flat
                        @keydown.space.prevent
                        background-color="#E9E9E9"
                        color="primary"
                        :rules="[required, vs.email_valid]"
                        v-model="account"
                        class="my-input-style mt-2"
                      />
                    </div>

                    <div style="position: absolute; bottom: 5vh">
                      <pages-btn
                        type="submit"
                        block
                        :loading="loading"
                        color="primary"
                        class="send_btn v-btn--text white-color"
                        >{{ $t("confirm_data") }}</pages-btn
                      >
                    </div>
                  </v-form>
                </div>
              </v-card>
            </div>
            <v-form
              v-else
              @submit.stop.prevent="checkFormEmail()"
              ref="form_email"
              v-model="valid_email"
              :lazy-validation="lazy_email"
            >
              <div class="div_email">
                <h3 class="email_title">E-mail</h3>
                <v-text-field
                  id="email_field"
                  solo
                  flat
                  @keydown.space.prevent
                  background-color="#E9E9E9"
                  color="primary"
                  :rules="[required, vs.email_valid]"
                  :label="$t('email')"
                  v-model="account"
                  placeholder="nome@email.com"
                  class="my-input-style mt-2"
                />
              </div>

              <pages-btn
                type="submit"
                block
                :loading="loading"
                color="primary"
                class="send_btn v-btn--text"
                :style="'margin-left: auto; margin-top: 55vh; color: var(--white)'"
                >{{ $t("send") }}</pages-btn
              >
            </v-form>
          </div>
          <v-alert class="ma-2" v-if="error" type="error">
            {{ $t(error) }}
          </v-alert>
        </div>
      </v-slide-y-transition>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PagesBtn from "@/views/pages/components/Btn.vue";
import ValidationService from "@/services/ValidationService";

export default {
  name: "ResetPassword",
  components: {
    "pages-btn": PagesBtn,
  },
  data: () => ({
    gs: new GeneralServices(),
    vs: new ValidationService(),
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Português",
        value: "pt",
      },
    ],
    selectedLanguage: null,
    loading: false,
    valid_email: true,
    lazy_email: false,
    valid_pin: true,
    error: null,
    lazy_pin: false,
    email_sent: false,
    pin: null,
    user_id: "",
    account: "",
    step: 1,
  }),
  watch: {
    step() {
      this.$store.commit("SET_STEP", this.step);
    },
  },
  computed: {
    color() {
      if (
        this.configs &&
        this.$route.query.partner_name != "DXAInvestPrivate"
      ) {
        return this.configs.color;
      } else {
        return "#01D281";
      }
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    checkBackFunction: function () {
      if (this.step == 1) {
        this.$router.push({ name: "LoginClient" });
      } else {
        this.goBack();
      }
    },
    checkFormEmail: function () {
      this.loading = true;
      var result = this.$refs.form_email.validate();
      if (result) {
        this.reset_password();
      } else {
        this.loading = false;
      }
    },
    reset_password: function () {
      var data = { Email: this.account };
      var client = new ApiService();
      client
        .postRequest("user/forgot-password-link", data)
        .then((result) => {
          this.loading = false;
          this.$toast.success(this.$t(result.content.message));
          this.user_id = result.content.userId;
          this.email_sent = true;
          if (this.step == 1) {
            this.goFoward();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    checkFormPin: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form_pin.validate();
      if (result) {
        this.validate_pin();
      } else {
        this.loading = false;
      }
    },
    validate_pin: function () {
      this.error = null;
      var data = { Code: parseInt(this.pin), UserId: this.user_id };
      var client = new ApiService();
      client
        .postRequest("user/forgot-password/validate", data)
        .then((result) => {
          this.loading = false;
          this.$router.push({
            name: "NewPasswordClient",
            query: { user_id: this.user_id, pin: this.pin },
          });
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
          this.loading = false;
        });
    },
    goFoward() {
      this.step += 1;
    },
    goBack() {
      this.step -= 1;
    },
    goNewPassword: function () {
      this.$router.push({
        name: "NewPasswordClient2",
        query: { user_id: this.user_id, pin: this.pin },
      });
    },
    selectedLanguageAvatar: function () {
      if (this.selectedLanguage.value.toUpperCase() == "PT") {
        return require("../../../../assets/Brasil-Icon.png");
      } else {
        return require("../../../../assets/UK-Icon.png");
      }
    },
    changeLanguage: function (l) {
      this.$i18n.locale = l.value;
      this.$store.commit("SET_LANGUAGE", l.value);
      this.selectedLanguage = l;
      this.$router.go();
    },
  },
  mounted() {
    this.langs.forEach((element) => {
      if (this.$i18n.locale == element.value) {
        this.selectedLanguage = element;
      }
    });
  },
};
</script>

<style>
.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi {
  background-color: var(--primary) !important;
}

.app_bar {
  display: none;
}
</style>

<style scoped>
.btn_lang {
  text-align: center;
  justify-content: Center;
  align-items: center;
  background-color: var(--white);
}
p {
  margin: 3px;
}

.white-color {
  color: var(--white) !important;
}

.p_en {
  color: var(--secondary);
}
.p_br {
  color: var(--primary);
  font-size: 12px;
}

.desktop_card {
  background-color: var(--dark) !important;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 12px !important;
  width: 64vw;
  height: 64vh;
  margin-top: 1vh;
}

#reset-password {
  background-color: var(--white);
  min-height: 100vh;
  min-width: 100vw;
}

.confirm_title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: var(--dark);
}

.confirm_content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.div_email {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.email_title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9c9c9c;
}

.send_btn {
  position: relative;
  border-radius: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px !important;
  min-width: 80% !important;
  margin-right: auto;
  height: 50px !important;
}

.steps {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: var(--white);
}

.card_content {
  width: 65%;
  height: 100%;
  background-color: var(--white);
  padding: 4% 2%;
  border-bottom-right-radius: 0 !important;
}

.x_logo {
  position: absolute;
  right: 30px;
  top: 40px;
  width: 6%;
}

.stars {
  position: absolute;
  transform: scaleX(-1);
  right: 30px;
  bottom: 42px;
  width: 28%;
}

.resend_btn {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  text-decoration: underline;
  letter-spacing: 0.15px;
}

.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> .v-text-field__slot input::placeholder,
.my-input-style >>> .v-input__icon button {
  color: #9c9c9c;
}

.v-toolbar__content {
  padding-left: 20px;
}

.v-progress-linear__background {
  background-color: transparent !important;
}
</style>
